



















































import { Vue } from 'vue-property-decorator';
import { dispatchValidateSmsCode } from '@/store/main/actions';

const UserProps = Vue.extend({
  props: {
    user_id: String,
  },
});

export default class ValidateCode extends UserProps {
  public valid = false;

  private KEYBOARD_KEYS = {
    arrowRight: 39,
    arrowLeft: 37,
    backspace: 8,
  };

  private inputValues: string[] = ['', '', '', '', '', ''];

  public inputListener(e, i) {
    const validationRefs: any = this.$refs.validationRefs;
    const currentRef = validationRefs[i];

    const nextRef = validationRefs[i + 1];
    this.inputValues[i] = currentRef.value;

    if (nextRef && currentRef.value) {
      nextRef.focus();
      if (nextRef.value) {
        nextRef.select();
      }
    }
    this.checkSubmit();
  }

  public validateCode() {
    const confirmationCode = this.inputValues.join('');

    dispatchValidateSmsCode(this.$store, {
      code: confirmationCode,
      user_id: this.user_id,
    });
  }

  public keyEventListener(e, i) {
    const validationRefs: any = this.$refs.validationRefs;
    const currentRef: any = validationRefs[i];
    const nextRef: any = validationRefs[i + 1];
    const prevRef = validationRefs[i - 1];

    switch (e.keyCode) {
      case this.KEYBOARD_KEYS.backspace:
        if (currentRef.value) {
          currentRef.value = '';
          this.inputValues[i] = currentRef.value;
        }
        break;
      case this.KEYBOARD_KEYS.arrowLeft:
        if (!prevRef) {
          break;
        }
        prevRef.focus();
      case this.KEYBOARD_KEYS.arrowRight:
        if (nextRef) {
          nextRef.focus();
        }
        break;
      default:
        try {
          const inputNumber = parseInt(e.key, 10);
          if (inputNumber && !isNaN(inputNumber)) {
            currentRef.value !== e.keyCode ? (currentRef.value = '') : currentRef.value = currentRef.value ;
          }
        } catch (err) {
          break;
        }
        break;
    }
    this.checkSubmit();
  }

  private checkSubmit() {
    this.valid = this.inputValues.every((value) => value.length > 0);
    this.$forceUpdate();
  }
}
